html {
  font-size: 16px;
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'DBOzoneX';
}

h1,
h2,
h3,
h4,
h5,
h6,
p
{
  margin: 0;
}