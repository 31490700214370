input {
  -webkit-appearance: none;
}

// this below class is override the css class of ant design

.ant-form label {
  font-size: $size-5;
}

.ant-form-item-label > label {
  line-height: 1.05;
  height: auto;
  font-size: $size-5;
  font-weight: bold;
}

.ant-btn.ant-btn-primary {
  background: #3f5963;
  height: 65px;
  border-radius: 0;
  font-size: 1.5rem;
  border: none;
}

.ant-checkbox-group, .ant-checkbox-wrapper {
  width: 100%;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label{
    padding: 0 0 3px;
  }
}