$confirm-border-radius: 7px;
$animate-duration: $transition-duration;
$animate-timing: $transition-timing;
$types: (error: rgb(225, 51, 46));

.confirm {
  position: fixed;
  z-index: $confirm-z-index;
  top: 0;
  height: 100%;
  width: 100%;

  &.is-opened {
    .confirm-backdrop {
      animation: fade-blur-in $animate-duration $animate-timing forwards;
    }

    .confirm-card {
      animation: zoom-in $animate-duration $animate-timing forwards;
    }
  }

  &.is-closed {
    .confirm-backdrop {
      animation: fade-blur-out $animate-duration $animate-timing alternate forwards;
    }

    .confirm-card {
      animation: zoom-out $animate-duration $animate-timing alternate forwards;
    }
  }

  &-backdrop {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: transparent;

    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 72px);
      min-height: 300px;
    }
  }

  &-card {
    border-radius: $confirm-border-radius;
    background-color: white;
    overflow: hidden;

    &-content {
      background-color: #fafafa;
      padding: 35px;
    }

    &-icon-container {
      text-align: center;
      margin-bottom: 24px;
    }

    &-icon {
      font-size: 60px;
    }

    &-title, &-description {
      text-align: center;
    }

    &-title {
      font-size: $size-3;
      font-weight: bold;
      text-transform: capitalize;
    }

    &-description {
      font-size: $size-5;
    }

    &-buttons {
      display: flex;
      height: 57px;

      & > button {
        flex: 1;
        font-size: $size-4;
        color: #000000;
        background-color: #FFFFFF;
        border: 0;
        border-radius: 0;
        outline: none;

        &.is-default-style {
          color: #000000;
        }
        
        &.is-cancel-style {
          color: #bdbdbd;
        }
      }
    }
  }
}