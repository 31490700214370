$padding-layout: 16px;

// font-size
$size-1:    2.71rem;
$size-2:    2.14rem;
$size-3:    1.71rem;
$size-4:    1.42rem;
$size-5:    1.15rem;
$size-6:    1rem;
$size-7:    0.7rem;
$sizes: $size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7;

$transition-duration: .3s;
$transition-timing: ease-in-out;
