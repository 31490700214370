.profile {
  width: 300px;
  margin-bottom: $padding-layout;

  &-card {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-avatar {
    margin-right: 24px;
  }

  &-detail {
    &-name {
      font-size: $size-4;
      font-weight: bold;
    }

    &-status {
      font-size: $size-5;
    }
  }
}