@each $size in $sizes {
  $index: index($sizes, $size);

  .is-size-#{$index} {
    font-size: $size;
  }
}

h1,
h2,
h3,
h4,
h5,
span,
p,
a,
b,
i,
strong
{
  line-height: 1;
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-size: $size-1;
}

h2 {
  font-size: $size-2;
}

h3 {
  font-size: $size-3;
}

h4 {
  font-size: $size-4;
}

h5 {
  font-size: $size-5;
}

p,
a,
b,
i,
strong
{
  font-size: $size-6;
}