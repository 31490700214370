@keyframes fade-blur-in {
  from {
    backdrop-filter: blur(0px) brightness(100%);
  }

  to {
    backdrop-filter: blur(5px) brightness(60%);
  }
}

@keyframes fade-blur-out {
  from {
    backdrop-filter: blur(5px) brightness(60%);
  }

  to {
    backdrop-filter: blur(0px) brightness(100%);
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}